import { toggleMenu } from "./header";
import { scrollToHash, getSamePageAnchor } from "./smooth-scrolling";
import '../css/main.css';


document.addEventListener("DOMContentLoaded", () => {
  /**
   * Toggle del header
   */
  toggleMenu()

  /**
   * Gallery carousel
   */
  const gallery = document.querySelectorAll(".gallery");
  if (gallery.length > 0) {
    import("./gallery").then((module) => {
      gallery.forEach((slider) => {
        module.carouselHandler(slider);
      });
    });
  }

  /**
   * Tabs
   */
  const tabs = document.querySelectorAll(".tabs");
  if (tabs.length > 0) {
    import("./tabs").then((module) => {
      tabs.forEach((block) => {
        module.tabsHandler(block);
      });
    });
  }

  /**
   * Posts carousel
   */
  const postsCarousel = document.querySelectorAll(".posts-carousel");
  if (postsCarousel.length > 0) {
    import("./posts-carousel").then((module) => {
      postsCarousel.forEach((block) => {
        module.carouselHandler(block);
      });
    });
  }

  /**
   * Testimonial form
   */
  const testimonialsForm = document.querySelectorAll("#testimonials_form");
  if (testimonialsForm.length > 0) {
    import("./testimonials-form").then((module) => {
      testimonialsForm.forEach((form) => {
        module.testimonialsFormHandler(form);
      });
    });
  }

  /**
   * Testimonials carousel
   */
  const testimonialsCarousel = document.querySelectorAll(".testimonials-carousel");
  if (testimonialsCarousel.length > 0) {
    import("./testimonials-carousel").then((module) => {
      testimonialsCarousel.forEach((block) => {
        module.testimonialsCarouselHandler(block);
      });
    });
  }

  /**
   * Smooth scrolling support
   */
  // If a link's href is within the current page, scroll to it instead
  document.querySelectorAll('a[href]').forEach(a => {
    a.addEventListener('click', e => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash);

  /**
   * ACF7 event listener
   */
  document.addEventListener('wpcf7mailsent', (event) => {
    gtag('event', 'Formulario', {
      'event_category': event.detail.contactFormId,
      'event_label': event.detail.unitTag
    });
  }, false);
});



