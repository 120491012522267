import anime from "animejs";

export function toggleMenu() {
  const hamburger = document.querySelector('#hamburger');
  const mainMenu = document.querySelector('#main-menu');
  const tl = anime.timeline({
    targets: mainMenu,
    autoplay: false,
    easing: 'easeInOutSine'
  }).add({
    height: '100vh',
    duration: 1,
  }).add({
    opacity: 1,
    duration: 300,
  });
  hamburger.addEventListener('click', () => {
    document.body.classList.toggle('open');
    hamburger.classList.toggle('open');
    if (!mainMenu.classList.contains('active')) {
      mainMenu.classList.add('active');
      if (tl.reversed) {
        tl.reverse();
      }
      tl.play();
    } else {
      mainMenu.classList.remove('active');
      tl.reverse();
      tl.play();
    }
  });

  const parentItem = document.querySelectorAll('.menu-item-has-children');
  if (window.matchMedia('(max-width: 1024px)').matches) {
    parentItem.forEach(item => {
      const toggleBtn = document.createElement('button');
      toggleBtn.innerHTML = '<i class="fas fa-caret-down"></i>';
      item.append(toggleBtn)

      toggleBtn.addEventListener('click', () => {
        item.classList.toggle('open');
      });
    });
  }
}
